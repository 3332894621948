import React from 'react';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';

const BlogIndexPage = () => {
  return (
    <Layout title="Blog">
      <BlogRoll />
    </Layout>
  );
};

export default BlogIndexPage;
